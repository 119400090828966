import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, CardColumns, Card } from 'react-bootstrap';
import BadgeMCT22Webp from '../img/badges/webp/MCT-Microsoft_Certified_Trainer_22_23.webp';
import Badge500Webp from '../img/badges/webp/EXAM-Associate-MB-500.webp';
import Badge330Webp from '../img/badges/webp/exam-mb330-600x600.webp';
import Badge300Webp from '../img/badges/webp/exam-mb300-600x600.webp';
import BadgeFOADWebp from '../img/badges/webp/CERT-Associate-Dynamics365-Finance-and-Operations-Apps-Developer.webp';
import BadgeSCMWebp from '../img/badges/webp/CERT-Associate-Dynamics365-Supply-Chain-Management-Functional-Consultant.webp';
import Badge894Webp from '../img/badges/webp/Exam-Development-Extensions-and-Deployment.webp';
import Badge6890Webp from '../img/badges/webp/Exam-Microsoft-Dynamics-AX-Development-Introduction-01.webp';
import BadgeMicrosoftSealWebp from '../img/badges/webp/microsoft-seal.webp';
import BadgeMCT22 from '../img/badges/MCT-Microsoft_Certified_Trainer_22_23.png';
import Badge500 from '../img/badges/EXAM-Associate-MB-500.png';
import Badge330 from '../img/badges/exam-mb330-600x600.png';
import Badge300 from '../img/badges/exam-mb300-600x600.png';
import BadgeFOAD from '../img/badges/CERT-Associate-Dynamics365-Finance-and-Operations-Apps-Developer.png';
import BadgeSCM from '../img/badges/CERT-Associate-Dynamics365-Supply-Chain-Management-Functional-Consultant.png';
import Badge894 from '../img/badges/Exam-Development-Extensions-and-Deployment.png';
import Badge6890 from '../img/badges/Exam-Microsoft-Dynamics-AX-Development-Introduction-01.png';
import BadgeMicrosoftSeal from '../img/badges/microsoft-seal.png';
import BadgePSMI from '../img/badges/badge-psmi.svg';
import '../css/Certification.css';

export default function Certifications() {
  const {t} = useTranslation();

  return (
    <>
      <Container fluid className="gradient py-5">
        <Container>
          <Row className="pb-3">
            <Col sm={12} className="text-center">
              <h2>{t('Personal certifications')}</h2>
            </Col>
          </Row>
          <Row>
            <CardColumns>
              <Certification title="Microsoft Certified Trainer" subtitle="2022-2023" webp={BadgeMCT22Webp} other={BadgeMCT22} />
              <Certification title="Dynamics 365: Finance and Operations Apps Developer Associate" subtitle="Microsoft Certified" webp={BadgeFOADWebp} other={BadgeFOAD} />
              <Certification title="Microsoft Dynamics 365: Finance and Operations Apps Developer" subtitle="Microsoft Exam" webp={Badge500Webp} other={Badge500} />
              <Certification title="Dynamics 365 Supply Chain Management Functional Consultant Associate" subtitle="Microsoft Certified" webp={BadgeSCMWebp} other={BadgeSCM} />
              <Certification title="Microsoft Dynamics 365 Supply Chain Management" subtitle="Microsoft Exam" webp={Badge330Webp} other={Badge330} />
              <Certification title="Professional Scrum Master I" subtitle="Scrum.org Certified" other={BadgePSMI} />
              <Certification title="Microsoft Dynamics 365: Core Finance and Operations" subtitle="Microsoft Exam" webp={Badge300Webp} other={Badge300} />
              <Certification title="Development, Extensions and Deployment for Microsoft Dynamics 365 for Finance and Operations" subtitle="Microsoft Exam" webp={Badge894Webp} other={Badge894} />
              <Certification title="Microsoft Certified Professional" subtitle="Microsoft Certified Professional" webp={BadgeMicrosoftSealWebp} other={BadgeMicrosoftSeal} />
              <Certification title="Microsoft Dynamics AX Development Introduction" subtitle="Microsoft Specialist" webp={BadgeMicrosoftSealWebp} other={BadgeMicrosoftSeal} />
              <Certification title="Microsoft Dynamics AX Development Introduction" subtitle="Microsoft Exam" webp={Badge6890Webp} other={Badge6890} />
              <Certification title="Microsoft Dynamics AX 2012 Development Introduction" subtitle="Microsoft Specialist" webp={BadgeMicrosoftSealWebp} other={BadgeMicrosoftSeal} />
              <Certification title="Microsoft Dynamics AX 2012 Development Introduction" subtitle="Microsoft Exam" webp={Badge6890Webp} other={Badge6890} />
            </CardColumns>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export function Certification(props) {
  return (
    <Card className="certificate">
      <Card.Header>
        <Card.Title>{props.title}</Card.Title>
        <Card.Subtitle>{props.subtitle}</Card.Subtitle>
      </Card.Header>
      <picture>
        {props.webp && <source type="image/webp" srcSet={props.webp} />}
        <source type="image/jpeg" srcSet={props.other} />
        <img src={props.other} alt={props.title} className="card-img-bottom" />
      </picture>
    </Card>
  );
}