import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Pim from '../img/pim_debaere.jpg';
import PimWebp from '../img/pim_debaere.webp';
import '../css/WhoIs.css';

export default function WhoIs() {
  const {t} = useTranslation();

  return (
    <>
      <Container>
        <Row className="py-5">
          <Col sm={6} className="order-sm-1 order-1">
            <h3 className="subheadline">{t('A brief introduction')}</h3>
            <h2 className="headline">{t('Who is Pim Debaere?')}</h2>
            <p>{t('Who is first paragraph')}</p>
            <p>{t('Who is second paragraph')}</p>
            <p>{t('Who is third paragraph')}</p>
          </Col>
          <Col sm={6} className="order-sm-2">
            <picture>
              <source type="image/webp" srcSet={PimWebp} />
              <source type="image/jpeg" srcSet={Pim} />
              <img src={Pim} alt="Pim Debaere" className="cover-photo photo-shadow" />
            </picture>
          </Col>
        </Row>
      </Container>
    </>
  );
}