import React from 'react';
import '../css/Loader.css';

export default function SchemaOrg(props) {
  return (
    <script type="application/ld+json">
      {`{
        "@context": "https://schema.org/",
        "@type": "` + props.type + `",
        "name": "pimaxplus.com",
        "url": "` + props.url + `",
        "publisher": "pimaxplus.com",
        "author": {
          "@type": "Person",
          "name": "` + (props.author ? props.author : 'Pim Debaere') + `"
        },
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "https://pimaxplus.com/"
        },
        "datePublished": "` + props.datePublished + `",
        "dateModified": "` + props.datePublished + `",
        "image": "` + (props.image ? props.image : 'https://pimaxplus.com/favicons/android-chrome-512x512.png') + `",
        "headline": "` + props.description + `",
        "description": "` + props.description + `",` +
        (props.about ? `"about": "` + props.about + `",` : ``) +
        `"sameAs" : [
          "https://twitter.com/pimaxplus",
          "https://www.linkedin.com/company/pimaxplus",
          "https://www.facebook.com/pimaxplus"
          ]
      }`}
    </script>
  );
}