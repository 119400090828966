import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faUser, faLightbulb } from '@fortawesome/free-solid-svg-icons';

export default function Introduction() {
  const {t} = useTranslation();

  return (
    <Container fluid className="alternate">
      <Row>
        <Col sm={4}>
          <FontAwesomeIcon icon={faUser} size="4x" aria-label="Who" aria-labelledby="introWho" />
          <h5 id="introWho">{t('Who')}</h5>
          <p>
            {t('Who content', {
              age: new Date(Date.now() - new Date(1989, 3, 21).getTime()).getUTCFullYear() - 1970,
              yearsExperience: new Date().getFullYear() - 2004
            })}
          </p>
        </Col>
        <Col sm={4}>
          <FontAwesomeIcon icon={faChartLine} size="4x" aria-label="What" aria-labelledby="introWhat" />
          <h5 id="introWhat">{t('What')}</h5>
          <p>{t('What content')}</p>
        </Col>
        <Col sm={4}>
          <FontAwesomeIcon icon={faLightbulb} size="4x" aria-label="How" aria-labelledby="introHow" />
          <h5 id="introHow">{t('How')}</h5>
          <p>{t('How content')}</p>
        </Col>
      </Row>
    </Container>
  );
}