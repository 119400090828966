import React, { lazy } from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../components/Banner';
import Introduction from '../components/Introduction';
import WhoIs from '../components/WhoIs';
// import Skills from '../components/Skills';
import Certifications from '../components/Certifications';
import { useTranslation } from 'react-i18next';
import SchemaOrg from './SchemaOrg';
import i18n from '../i18n';

export default function Home() {
  const Skills = lazy(() => import('../components/Skills'));
  const lng = i18n.language.split('-')[0];
  const {t} = useTranslation();

  return (
    <>
      <Helmet>
        <title>pimaxplus.com &ndash; IT consultancy</title>
        <meta name="description" content={t('Freelance IT consultancy, developer in different programming languages and in different types of businesses.')} />
        <meta property="og:description" content={t('Freelance IT consultancy, developer in different programming languages and in different types of businesses.')} />
        <meta property="og:title" content="pimaxplus.com &ndash; IT consultancy" />
        <meta property="og:url" content={'https://pimaxplus.com/' + lng} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://pimaxplus.com/favicons/android-chrome-512x512.png" />
        <meta property="og:image_alt" content="pimaxplus.com" />
        <meta property="og:image:width" content="512" />
        <meta property="og:image:height" content="512" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@pimaxplus" />
        <meta name="twitter:title" content="pimaxplus.com &ndash; IT consultancy" />
        <meta name="twitter:description" content={t('Freelance IT consultancy, developer in different programming languages and in different types of businesses.')} />
        <meta name="twitter:image" content="https://pimaxplus.com/favicons/android-chrome-512x512.png" />
      </Helmet>
      <Banner main={true} title="IT consultancy" prefix="Freelance" />
      <WhoIs />
      <Introduction />
      <Skills />
      <Certifications />
      <SchemaOrg 
        type="Website"
        url={'https://pimaxplus.com/' + lng}
        datePublished="2020-07-12"
        description={t('Freelance IT consultancy, developer in different programming languages and in different types of businesses.')} />
    </>
  );
}