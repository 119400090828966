import React from 'react';
import { Carousel } from 'react-bootstrap';
import { canUseWebP } from 'react-img-webp';

export default function Main(props) {
  const canUseWebp = canUseWebP();

  return (
    <Carousel className={(props.main ? 'main-banner' : '') + ' banner ' + (canUseWebp ? 'webp' : 'no-webp')} controls={false} indicators={false}>
      <Carousel.Item>
        <Carousel.Caption>
          {props.prefix && <p>{props.prefix}</p>}
          <h1>{props.title}</h1>
          {props.sufix && <p>{props.sufix}</p>}
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}